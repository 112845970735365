<template>
  <layout :hasSearch="false" :hasTopNav="false">
    <v-dialog v-model="openDelModal">
      <div class="w-[300px] bg-white mx-auto p-3 rounded flex flex-col gap-4">
        <Text weight="600" class="text-center" :size="20">
          Delete Recipe
        </Text>
        <Text weight="600" class="text-center" :size="14">
          Are you sure you want to delete {{ meal?.name }}?
        </Text>
        <div class="flex gap-2">
          <button
            class="border p-2 rounded w-[fit-content] mx-auto"
            @click="
              () => {
                openDelModal = false;
              }
            "
          >
            Cancel
          </button>
          <button
            class="p-2 text-white rounded w-[fit-content] bg-[red] mx-auto"
            @click="
              () => {
                deleteRecipe();
              }
            "
          >
            Delete
          </button>
        </div>
      </div>
    </v-dialog>
    <div class="add-recipe-body">
      <top-nav :arrowback="true" :searchBar="false" />
      <Text :size="24" :weight="700">{{ isEdit ? "Edit" : "Add" }} Recipe</Text>

      <form
        action=""
        class="mt-[40px] flex flex-col gap-[40px]"
        @submit="submit"
      >
        <div v-if="!isEdit" class="flex gap-2 cursor-pointer">
          <input
            type="checkbox"
            v-model="isCustom"
            @input="() => (meal = {})"
          /><Text :size="17" :weight="500">Custom</Text>
        </div>
        <div class="flex flex-col w-100 gap-3" v-if="isCustom">
          <div class="flex flex-col gap-[20px]">
            <label for="">Meal Name</label>
            <input class="ue-input-field drop" v-model="meal.name" />
          </div>
          <div class="flex flex-col gap-[20px]">
            <label for="">Meal Image</label>
            <input accept="image/*" type="file" @input="selectMealImage" />

            <img :src="previewImg" class="meal-image" />

            <img
              v-if="isCustom"
              class="meal-image"
              :src="
                getImgFromFirebase(
                  'customMeals',
                  meal?.image?.replace('customMeals/', '')
                )
              "
            />
          </div>
        </div>

        <div class="flex flex-col w-100 gap-3" v-else>
          <div class="flex flex-col gap-[20px]">
            <label for="">Meal Name </label>
            <ue-select
              class="ue-input-field drop"
              :options="[
                ...meals
                  .filter((meal) => meal?.type?.id == 'raw')
                  .map((meal) => ({
                    ...meal,
                    // id: `${meal.name}-${meal.restaurant_name}`,
                    id: `${meal.name}`,
                  })),
              ]"
              label="name"
              v-model="meal"
              :disabled="isEdit"
            >
              <template #option="option">
                <span :key="option.id"
                  >{{ option.name }} ({{ option.restaurants.join(", ") }})</span
                >
              </template>
            </ue-select>
          </div>
          <div class="flex flex-col gap-[20px]">
            <label for="">Meal Image</label>
            <img :src="meal?.image" class="meal-image" />
          </div>
        </div>

        <div class="w-full mb-5">
          <Text :size="17" :weight="500">Recipe </Text>
          <table class="recipe-table">
            <thead>
              <tr class="bb">
                <th class="br text text-right">Recipes</th>
                <th class="text text-center bl">Ingredient</th>
                <th class="text text-center bl">Qty</th>
                <th class="text text-center bl">Cost (₦)</th>
                <th class="text text-center bl">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="br"></td>
                <td class="br">
                  <ue-select
                    @click="getIngredients"
                    v-model="item.ingredient"
                    class="drop"
                    label="name"
                    :options="[
                      ...ingredients.map((inge) => ({
                        name: inge.name,
                        unit: inge.unit,
                        type: inge?.type || inge?.meal?.type,
                      })),
                    ]"
                  >
                    <template #option="option">
                      <span>{{ option.name }}</span>
                    </template>
                  </ue-select>
                </td>
                <td class="br">
                  <input
                    class="border outline-none rounded w-[100%] py-[9px]"
                    type="number"
                    v-model="item.quantity"
                    min="0"
                  />
                  <br />{{ item?.ingredient?.unit?.short }}
                </td>
                <td class="br">
                  <input
                    class="border outline-none rounded w-[100%] py-[9px]"
                    type="number"
                    v-model="item.cost"
                    min="0"
                  />
                </td>
                <td>
                  <img
                    :src="icons.x"
                    alt=""
                    class="cursor-pointer"
                    @click="() => deleteItem(item.id)"
                  />
                </td>
              </tr>
              <tr class="bt">
                <td class="br"></td>
                <td>
                  <Text
                    class="add-inge-btn text cursor-pointer"
                    @click="addItem"
                    >+ Add An Ingredient</Text
                  >
                </td>
              </tr>
              <tr class="bt">
                <td class="br text-right text">Recipe Yield</td>
                <td colspan="3">
                  <input
                    class="border outline-none rounded w-[60%] py-[15px]"
                    type="number"
                    min="0"
                    v-model="portion"
                  />
                  <Text :size="17" :weight="500" class="inline">
                    Portion(s)</Text
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex gap-2">
          <ue-button
            :variant="'lg'"
            @click="() => (openDelModal = true)"
            class="mb-5 mx-auto  border-solid border-2 border-red-600 text-[red] border-[1px]"
            v-if="isEdit"
            type="button"
          >
            Delete Recipe
          </ue-button>
          <ue-button :variant="'lg'" :disabled="loading" class="mb-5 mx-auto">
            {{
              loading ? " loading..." : isEdit ? "Save Recipe" : "Create Recipe"
            }}
          </ue-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Text from "@/components/Text.vue";
import layout from "@/partials/Layout/layout.vue";
import TopNav from "@/components/TopNav.vue";
import { icons } from "@/assets/icons";
import { onMounted, ref, watchEffect } from "vue";
import { meals as mealStore } from "@/views/store/meals";
import { get, ref as dbRef, getDatabase, set, remove } from "firebase/database";
import { useToast } from "vue-toast-notification";
import { useRoute, useRouter } from "vue-router";
import UeButton from "@/components/UeButton.vue";
import { ref as uploadRef, uploadBytes } from "firebase/storage";
import { projectStorage } from "@/firebase/config";
import { getImgFromFirebase } from "@/util";
import { loadingScreen } from "@/views/store/loadingScreen";
export default {
  components: { layout, Text, TopNav, UeButton },
  setup() {
    const meal = ref({});
    const ingredients = ref([]);
    const portion = ref("");
    const toast = useToast({ position: "top-right" });
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const isEdit = route.params.id != "add";
    const meals = ref(mealStore.state.value);
    const isCustom = ref(false);
    const previewImg = ref("");
    const openDelModal = ref(false);
    const img = ref({});
    mealStore.dispatch("updateMeals");
    const items = ref([
      {
        id: Math.round(Math.random() * 1000000),
        quantity: 0,
        cost: 0,
        ingredient: null,
      },
    ]);

    const getIngredients = async () => {
      // console.log(items.value);
      const res = await get(dbRef(getDatabase(), `/inventory/ingredients`));
      const recipe_res = await get(dbRef(getDatabase(), `/inventory/recipes`));
      if (res.val() && recipe_res.val()) {
        //add custom recipes to ingredients
        // console.log(first)
        const custom_recipes = Object.values(recipe_res.val())
          .filter((recipe) => recipe?.meal?.type?.id == "custom")
          .map((recipe) => ({
            name: recipe.meal.name,
            ...recipe,
            unit: { short: "portions" },
          }));
        // console.log(custom_recipes)
        const ingredients_array = [
          ...Object.values(res.val()),
          ...custom_recipes,
        ];
        const only_raw_ingredients = ingredients_array.filter(
          (ingredient) => ingredient?.type?.id !== "ready_to_serve"
        );

        // don't display items that are already selected
        const chosen_items = items.value.map((item) => item.ingredient?.name);
        const extracted_ingredients = only_raw_ingredients.filter(
          (ingredient) => !chosen_items.includes(ingredient.name)
        );
        console.log(extracted_ingredients);
        ingredients.value = extracted_ingredients;
      }
    };

    watchEffect(() => {
      getIngredients();
    });

    watchEffect(async () => {
      if (route.params.id) {
        const recipe_res = await get(
          dbRef(getDatabase(), `/inventory/recipes/${route.params.id}`)
        );
        if (recipe_res.val()) {
          const recipe = recipe_res.val();
          // console.log(recipe.meal);
          meal.value = recipe.meal;
          items.value = recipe.items;
          portion.value = recipe.portion;
          isCustom.value = recipe?.meal?.type?.id === "custom";
        }
      }
    });
    const selectMealImage = (e) => {
      img.value = e.target.files[0];
      // console.log(img.value);
      previewImg.value = URL.createObjectURL(img.value);
    };

    onMounted(async () => {
      // console.log("rerender : sort meals");
      if (!isEdit) {
        let list = meals.value;
        const res = await get(dbRef(getDatabase(), `/inventory/recipes/`));
        const recipes = Object.keys(res.val());
        const newlist = list
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((meal) => !recipes.includes(meal.name));

        let combined = {};
        // combine meal that appears in multiple restaurants
        newlist.forEach((item) => {
          combined[item.name] = {
            ...combined[item.name],
            ...item,
          };
          if (combined[item.name].restaurants) {
            combined[item.name].restaurants.push(item.restaurant_name);
          } else {
            combined[item.name].restaurants = [item.restaurant_name];
          }
        });

        const combinedList = Object.values(combined || {});
        console.log(combinedList, newlist);
        meals.value = combinedList;
      }
    });
    const uploadImage = async () => {
      const image_url = Math.random() + img.value.name;
      const storageRef = uploadRef(projectStorage, `customMeals/${image_url}`);
      const res = await uploadBytes(storageRef, img.value);
      meal.value.image = res.ref.fullPath;
      // } else {
      //   toast.info("Please choose an image");
      // }
    };
    const addItem = () => {
      const item = {
        id: Math.round(Math.random() * 1000000),
        quantity: 0,
        cost: 0,
        ingredient: null,
      };
      items.value.push(item);
    };
    const deleteItem = (id) => {
      items.value = items.value.filter((item) => item.id != id);
    };
    const deleteRecipe = async () => {
      try {
        loadingScreen.commit("openLoadingScreen", { loading: true });
        await remove(
          dbRef(getDatabase(), `/inventory/recipes/${route.params.id}`)
        );

        router.push("/inventory/recipes/");
      } catch (error) {
        console.log(error);
      } finally {
        loadingScreen.commit("openLoadingScreen", { loading: false });
      }
    };
    const submit = async (e) => {
      const create = async () => {
        loading.value = true;
        const payload = {
          id: meal.value.name,
          meal: meal.value,
          items: items.value,
          portion: portion.value,
          // isCustom: isCustom.value,
        };
        const path = `inventory/recipes/${meal.value.name}`;
        const isExist = (await get(dbRef(getDatabase(), path))).val();

        try {
          // console.log(meal.value);
          if (!meal.value?.name && !meal.value?.image) {
            toast.info("Please enter a meal and image");
          } else {
            if (isEdit) {
              if (isCustom.value) {
                if (!meal.value.image) {
                  await uploadImage();
                }
                meal.value.type = { id: "custom", name: "Custom" };
              }
              // console.log(payload);
              await set(dbRef(getDatabase(), path), payload);
              toast.success("Recipe edited successfully");
              router.push("/inventory/recipes/");
            } else {
              if (!img.value?.name && isCustom.value) {
                toast.info("Please upload a photo");
              } else {
                if (isExist) {
                  toast.info(`${meal.value.name} already has a recipe`);
                } else {
                  if (isCustom.value) {
                    await uploadImage();
                    meal.value.type = { id: "custom", name: "Custom" };
                  }
                  await set(dbRef(getDatabase(), path), payload);
                  toast.success("Recipe created successfully");
                  router.push("/inventory/recipes/");
                }
              }
            }
          }
        } catch (error) {
          console.log("Error :", error);
        } finally {
          loading.value = false;
        }
      };

      e.preventDefault();
      let all_items_filled = items.value.every((item) => {
        let check_if_individual_item_field_is_not_empty = Object.values(
          item
        ).every((prev) => prev);
        return check_if_individual_item_field_is_not_empty;
      });
      if (!all_items_filled) {
        toast.info("Some recipe ingredient details not filled");
      } else {
        if (!meal.value) {
          toast.info("Please selet a meal");
        } else {
          if (!portion.value) {
            toast.info("Portion must be greater than 0");
          } else {
            create();
          }
        }
      }
    };

    return {
      icons,
      items,
      addItem,
      deleteItem,
      meals,
      meal,
      ingredients,
      submit,
      portion,
      loading,
      isEdit,
      getIngredients,
      isCustom,
      selectMealImage,
      previewImg,
      getImgFromFirebase,
      openDelModal,
      deleteRecipe,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-recipe-body {
  padding: 0px 40px;
  label {
    color: #000;
    font-family: Work Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 96% */
  }
  .meal-image {
    width: 300px;
  }
  input {
    padding-left: 12px;
  }

  .drop {
    font-size: 17px;
  }
  .recipe-table {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
    .bt {
      border-top: 1px solid black;
    }
    .bb {
      border-bottom: 1px solid black;
    }
    .bl {
      border-left: 1px solid black;
    }
    .br {
      border-right: 1px solid black;
    }
    thead,
    tbody {
      tr {
        height: 78px;
      }
      th,
      td {
        padding: 0 40px;
      }
      thead {
        height: 100px;
        padding: 20px 0;
        display: block;
        border-bottom: 1px solid black;
      }
      width: 100%;
    }

    .add-inge-btn {
      background: var(
        --Primary,
        linear-gradient(180deg, #ff9103 -36.96%, #f60100 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .text {
      font-family: Work Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 96% */
    }
  }
}
</style>