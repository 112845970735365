<template>
  <div
    class="mx-auto rounded-[24px] relative bg-white w-[65%] h-[75vh] edit-ingredient-inventory"
  >
    <header class="">
      <Text :color="'white'" :weight="600" :size="24">{{
        ingredient?.name
      }}</Text>
    </header>
    <main>
      <div class="flex gap-[24px]">
        <Text :color="'black'" :weight="600" :size="20">Based on</Text>

        <v-btn
          :class="`${
            action == single_action ? 'ue-btn-gradient' : 'ue-btn-outline '
          } capitalize`"
          v-for="single_action in actions"
          :key="single_action"
          :variant="action == single_action ? `tonal` : 'outlined'"
          @click="() => (action = single_action)"
        >
          {{ single_action }}
        </v-btn>
      </div>
      <form class="w-[100%] py-5 h-[100%] relative">
        <!-- ############################################# -->
        <div class="flex flex-col gap-[30px]" v-if="action == 'receive'">
          <flex-input
            :label="'Amount of stock received'"
            :unit="ingredient?.unit?.short"
          >
            <input type="number" v-model="formValues.quantity" min="0" />
          </flex-input>

          <flex-input :label="'Counted by'">
            <input type="text" disabled v-model="name" />
          </flex-input>
        </div>
        <!-- ############################################# -->

        <!-- ############################################# -->
        <div class="flex flex-col gap-[50px]" v-if="action == 'actual'">
          <flex-input
            :label="'Physical stock amount'"
            :unit="ingredient?.unit?.short"
          >
            <input type="number" v-model="formValues.quantity" min="0" />
          </flex-input>

          <flex-input
            :label="'System stock amount'"
            :unit="ingredient?.unit?.short"
          >
            <input
              type="number"
              v-model="ingredient.current_inventory"
              disabled
            />
          </flex-input>

          <flex-input :label="'Does it tally'">
            <input
              type="text"
              disabled
              :value="formValues.tally ? 'Yes' : 'No'"
            />
          </flex-input>

          <flex-input :label="'If no, why not? '" v-if="!formValues.tally">
            <textarea v-model="formValues.reason"></textarea>
          </flex-input>
        </div>
        <!-- ############################################# -->

        <!-- ############################################# -->
        <div class="flex flex-col gap-[50px]" v-if="action == 'damage'">
          <flex-input :label="'Damaged stock'" :unit="ingredient?.unit?.short">
            <input
              type="number"
              v-model="formValues.quantity"
              :max="ingredient?.current_inventory"
              min="0"
            />
          </flex-input>

          <flex-input :label="'Reason behind damage'">
            <textarea v-model="formValues.reason"></textarea>
          </flex-input>
        </div>
        <!-- ############################################# -->

        <div class="flex w-full gap-3 justify-end absolute bottom-5">
          <v-btn
            class="ue-btn-outline"
            variant="outlined"
            @click="() => props.onClose()"
          >
            Cancel
          </v-btn>
          <v-btn
            class="ue-btn-gradient"
            :disabled="loading"
            variant="tonal"
            @click="submit"
          >
            {{ loading ? "Loading..." : "Save" }}
          </v-btn>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { store } from "@/views/store/store";
import dayjs from "dayjs";
import { useToast } from "vue-toast-notification";
import FlexInput from "../Prep/FlexInput.vue";
import { getAllOccurancesOfItem } from "../../Kitchen/Meals/getAllOccurancesOfItem";
import { meals } from "@/views/store/meals";
import { multipleUpdates } from "../../../store/store";

export default {
  components: { Text, FlexInput },
  props: {
    onClose: Function,
  },
  setup(props) {
    const actions = ["receive", "actual", "damage"];
    const action = ref("receive");
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    const toast = useToast({ position: "top-right" });
    const formValues = ref({
      reason: "",
      tally: false,
      quantity: null,
    });

    const ingredient = ref({});
    const item_path = `/inventory/ingredients/${route.params.id}/`;
    watchEffect(async () => {
      const res = await get(dbRef(getDatabase(), item_path));
      ingredient.value = res.val();
      console.log(ingredient.value);
    });
    watchEffect(() => {
      formValues.value.tally =
        formValues.value.quantity == ingredient.value?.current_inventory;
    });
    const updateStoplist = async (name, state) => {

      console.log( meals.state.value.find((meal) => meal.name?.toLowerCase() === name?.toLowerCase()));
      let updates = [];
      const occurances = getAllOccurancesOfItem(
        meals.state.value,
        meals.state.value.find((meal) => meal.name?.toLowerCase() === name?.toLowerCase())
      );
      occurances?.occured_in.forEach((occurance) => {
        updates.push({ path: occurance.stoplist_path, value: state });
      });
      // console.log(updates);
      if (updates.length) await multipleUpdates({ updates });
    };
    const submit = async (e) => {
      const current_inventory = ingredient.value?.current_inventory;
      loading.value = true;
      e.preventDefault();
      const prevHistory = Object.values(ingredient.value?.history || {});
      let history = prevHistory?.length ? prevHistory : [];

      let user = store.state.user;
      let total = 0;
      let payload = {
        user,
        date: dayjs().format("MMM DD, YYYY"),
        time: dayjs().format("hh:mm A"),
        action: action.value,
      };

      if (action.value == "receive") {
        total = formValues.value.quantity + current_inventory;
        payload = { ...payload, ...formValues.value, total };
      }
      if (action.value == "actual") {
        total = formValues.value.quantity;
        let new_quantity = formValues.value.quantity - current_inventory;
        if (formValues.value.tally) {
          total = current_inventory;
          new_quantity = 0;
        }
        payload = {
          ...payload,
          ...formValues.value,
          total,
          quantity: new_quantity,
        };
      }

      if (action.value == "damage") {
        total = Math.abs(formValues.value.quantity - current_inventory);
        payload = {
          ...payload,
          ...formValues.value,
          total,
        };
      }

      try {
        const quantity = formValues.value.quantity,
          act = action.value,
          reason = formValues.value.reason,
          tally = formValues.value.tally;
        if (quantity <= 0) {
          toast.info("Inventory must be greater than 0");
        } else {
          if (act == "damage" && quantity > current_inventory) {
            toast.info(
              "Damaged quantity cannot be greater than current quantity"
            );
          } else {
            if (act == "damage" && !reason) {
              toast.info("Damage must have a reason!");
            } else {
              if (act == "actual" && !reason && !tally) {
                toast.info("Actual must have a reason when it does not tally");
              } else {
                history.push(payload);
                console.log(current_inventory, total);

                if (action.value === "receive" && current_inventory == 0) {
                  updateStoplist(ingredient.value.name, true);
                }
                if (action.value === "damage" && total == 0) {
                  updateStoplist(ingredient.value.name, false);
                }
                // updateStoplist(ingredient.value.name,);
                await set(dbRef(getDatabase(), item_path), {
                  ...ingredient.value,
                  history,
                  current_inventory: total,
                });
                toast.success("Ingredient inventory updated successfully");
                props.onClose();
                router.push(`/inventory/ingredients/${route.params.id}`);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };
    return {
      props,
      actions,
      action,
      formValues,
      submit,
      ingredient,
      loading,
      name: `${store.state.user.first_name} ${store.state.user.last_name}`,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-ingredient-inventory {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  header {
    width: 100%;
    height: 84px;
    flex-shrink: 0;
    border-radius: 16px 16px 0px 0px;
    background: #ff9103;
    padding: 40px;
    display: flex;
    align-items: center;
  }
  main {
    height: 100%;
    width: 100%;
    padding: 40px;
  }
  form {
    flex: 1;
    input,
    textarea {
      width: 266px;
      height: 33px;
      outline: none;
      //   border: 1px grey solid;
      padding: 12px;
      text-align: right;
      //   border-radius: 8px;
      border-radius: 6px;
      border: 1px solid #000;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    textarea {
      height: 200px;
      text-align: left;
    }
  }
}
</style>