<template>
  <layout :hasSearch="false" class="ingredients-body" :hasTopNav="false">
    <!-- <v-dialog v-model="openModal"> <add-ingredient /> </v-dialog> -->
    <div class="w-full p-5 flex-col gap-4">
      <header class="flex justify-between">
        <Text :size="24" :weight="600"> Ingredients </Text>
        <router-link to="/inventory/ingredients/add">
          <ue-button :variant="'md'"> Add ingredient </ue-button>
        </router-link>
      </header>
      <div class="w-full mt-5 items-center justify-between">
        <div class="search flex items-center gap-3">
          <span class="material-symbols-outlined"> search </span>
          <input type="search" placeholder="Search" v-model="search" />
        </div>
        <div class="flex float-right gap-3 mt-2">
          <ue-select
            class="w-[200px]"
            :options="[...statuses]"
            v-model="status"
            placeholder="All"
            label="label"
          >
            <template #option="option">
              <span>{{ option.label }}</span>
            </template> </ue-select
          ><ue-select
            class="w-[200px]"
            :options="['Raw', 'Ready to serve']"
            v-model="filterByType"
            placeholder="Type"
            label="label"
          >
            <template #option="option">
              <span>{{ option.label }}</span>
            </template>
          </ue-select>
        </div>
      </div>

      <table class="w-full gap-[25px] pt-[100px]">
        <thead>
          <tr class="w-full px-[0px] px-[24px]">
            <th>
              <Text :size="17" :weight="600" class="data_item">Photo</Text>
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item">Name</Text>
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item">Type</Text>
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item"
                >Primary Vendor</Text
              >
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item"
                >Current Inventory</Text
              >
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item">Status</Text>
            </th>
            <th>
              <Text :size="17" :weight="600" class="data_item">Action</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="rounded-[16px] h-[100px] items-center bg-white justify-between w-full px-[24px]"
            v-for="ingredient in paginatedList"
            :key="ingredient.id"
          >
            <td>
              <Text :size="17" :weight="500" class="data_item">
                <img
                  class="rounded"
                  v-if="ingredient?.image_url"
                  :src="ingredient?.image_url"
                />
                <span v-else>No image</span>
              </Text>
            </td>
            <td>
              <Text :size="17" :weight="500" class="data_item">{{
                ingredient?.name
              }}</Text>
            </td>
            <td>
              <Text :size="17" :weight="500" class="data_item">{{
                ingredient?.type?.name
              }}</Text>
            </td>
            <td>
              <Text :size="17" :weight="500" class="data_item">{{
                ingredient?.primary_vendor?.name ||
                ingredient?.primary_vendor?.vendor_name
              }}</Text>
            </td>
            <td>
              <Text :size="17" :weight="500" class="data_item"
                >{{ ingredient?.current_inventory }}
                {{ ingredient?.unit?.short }}</Text
              >
            </td>
            <td>
              <Text
                :size="17"
                :weight="500"
                class="flex-1 flex text-center data_item"
                ><p :class="`${ingredient.status.color} text-white px-3`">
                  {{ ingredient.status.message }}
                </p></Text
              >
            </td>
            <td>
              <Text
                @click="
                  () => router.push(`/inventory/ingredients/${ingredient.id}`)
                "
                :size="17"
                :weight="500"
                class="flex-1 underline cursor-pointer data_item"
                :color="'#F60100'"
                >View</Text
              >
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </layout>
</template>

<script>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import { computed, ref, watchEffect } from "vue";
import AddIngredient from "./AddIngredient.vue";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { getImg } from "@/util";
import { getIngredientStatus } from "./getIngredientStatus";
import { useRouter } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import UeButton from "@/components/UeButton.vue";
export default {
  components: { layout, Text, AddIngredient, Pagination, UeButton },
  setup() {
    const statuses = ["Available", "Low stock", "Out of stock"];
    const status = ref("");
    const openModal = ref(false);
    const search = ref("");
    const router = useRouter();
    const filterByType = ref("");

    const ingredients = ref([]);
    watchEffect(async () => {
      search.value;
      status.value;
      filterByType.value;
      const val = search.value?.toLowerCase();
      const data = await get(dbRef(getDatabase(), "inventory/ingredients"));

      if (data.val()) {
        // console.log(status.value);
        let list = Object.values(data.val())
          .map(getIngredientStatus)
          .filter(
            (value) =>
              value.name?.toLowerCase()?.includes(val) ||
              value.primary_vendor?.vendor_name?.toLowerCase()?.includes(val) ||
              value.type.name === filterByType.value
          );
        ingredients.value = list.filter((item) => {
          if (status.value) return item.status.message === status.value;
          return true;
        }).filter(item=>{
          if (filterByType.value) return item.type.name === filterByType.value;
          return true;
        });
        // ingredients.value = filterByType.value
        //   ? list.filter((item) => item.type.name == filterByType.value)
        //   : list;
      }
    });

    const perPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(ingredients.value?.length / perPage)
    );
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    const paginatedList = computed(() => {
      const startIndex = (currentPage.value - 1) * perPage;
      const endIndex = startIndex + perPage;
      return ingredients.value?.slice(startIndex, endIndex);
    });
    return {
      statuses,
      status,
      openModal,
      ingredients,
      search,
      getImg,
      router,
      goToPage,
      currentPage,
      paginatedList,
      totalPages,
      filterByType,
    };
  },
};
</script>

<style lang="scss" scoped>
.ingredients-body {
  img {
    width: 64px;
    height: 64px;
  }
  width: 100%;
  .search {
    border-radius: 30px;
    background: #fff;
    width: 100%;
    height: 39px;
    padding: 0 20px;
    display: flex;
    flex-shrink: 0;
    input {
      outline: none;
      height: 100%;
      width: 100%;
    }
  }

  .selecttttt {
    border: 1px black solid;
    border-radius: 4px;
  }
  select::-ms-expand {
    display: block !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1.4em;

    td:first-child {
      border-left-style: solid;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-left: 20px;
    }
    td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
    th:first-child {
      padding-left: 20px;
    }
    th:last-child {
      padding-right: 20px;
    }
  }
}
</style>