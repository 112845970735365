
import { projectFunctions } from "@/firebase/config";
import { formatPrice, getExtras, getOrderID, int, sum, writeOrderTotalInTimeline } from "@/util";
import dayjs from "dayjs";
import { get, getDatabase, ref as dbRef, set, child, query, orderByChild, limitToFirst, startAt, equalTo, remove, push } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { useToast } from 'vue-toast-notification';
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex"
import { getMealPointPrice } from "@/util/getMealPointPrice";
import { WalletTransaction } from "../main/Operator/Customers/updateWallet";
import { createCancelledSummary } from "../main/Operator/Cancelled/createCancelledSummary";
import { mealpointTransaction } from "@/util/mealpointrefund";
import { saveInitialOrderTotal } from "@/util/saveInitialOrderTotal";
import { meals } from "./meals";
import { getAllOccurancesOfItem } from "../main/Kitchen/Meals/getAllOccurancesOfItem";
// import { useRouter } from "vue-router";




// const router = useRouter()
const toast = useToast({ position: 'top-right' })
const saveReviewId = httpsCallable(projectFunctions, "saveReviewId");
export const saveMealPoint = httpsCallable(projectFunctions, "saveMealPoint");
export const sendPushNotification = httpsCallable(projectFunctions, "sendPushNotification");
export const orderUpdateMealInventory = httpsCallable(projectFunctions, "orderUpdateMealInventory");
export const createTimeLine = httpsCallable(projectFunctions, "createTimeLine");
export const multipleUpdates = httpsCallable(projectFunctions, "multipleUpdates");
export const rewardReferrer = httpsCallable(projectFunctions, "rewardReferrer");

let asseble_recheck_timer;

export const store = createStore({
    state() {
        return {
            modal: {
                state: false,
                data: null
            },
            orders: [],
            order_tabs: [],
            cancelledOrders: [],
            unconfirmedOrders: [],
            completedDeliveryOrders: [],
            completedPickupOrders: [],
            completedOrders: [],
            processingOrders: [],
            undefined_orders: [],
            scheduledOrders: [],
            riders: [],
            meals: [],
            customers: [],
            user: {},
            orders_with_meal_reviews: [],
            searchValue: '',
            cancelledLoading: null

        }
    },
    mutations: {
        login(state, data) {
            // console.log(data)
            state.user = data
        },

        logout(state) {
            state.user = {}
            state.orders = []
            state.unconfirmedOrders = []
            state.cancelledOrders = []
            state.customers = []
            state.scheduledOrders = []
            state = {}
            // router.push('/admin')
        },
        openModal(state, data) {
            state.modal = {
                state: true,
                data
            };
        },
        closeModal(state) {
            state.modal = {
                state: false,
                data: null
            };
        },
        search: (state, payload) => {
            state.searchValue = payload
        },

        updateUsers: (state, users) => {
            state.customers = (users)
        },
        updateCompletedOrders: (state, list) => {
            state.completedOrders = list
        },
        updateProcessingrders: (state, list) => {
            state.processingOrders = list
        },
        updateOrder: (state, orderId, newOrder) => {
        },
        updateOrders: (state, list) => {
            const updated_orders = (list.filter(order => !order.status?.AwaitingPayment?.state && !order.status?.Cancelled?.state && order.status?.Confirmed?.state && !order?.isSchedule))
            state.orders = updated_orders
            state.order_tabs.operator_active = updated_orders?.filter(order => {
                if (order?.locationType == 'Delivery') {
                    return !order?.status?.Delivered?.state
                } else {
                    return !order?.status?.Pickup?.state
                }
            })
            state.order_tabs.kitchen_active = updated_orders?.filter(order => sum(order.items?.map((x) => order.prepared)) !== sum(order.items.map((x) => order.quantity)) - sum(order.items.map((x) => order.cancelled)))
            state.order_tabs.assemble_active = updated_orders?.filter(order => {
                if (order?.locationType == 'Delivery') {
                    return order?.status?.Assembled?.state && !order?.status?.Dispatched?.state
                } else {
                    return order?.status?.Assembled?.state && !order?.status?.Pickup?.state
                }
            })
            state.order_tabs.operato_active = updated_orders?.filter(order => {
                if (order?.locationType == 'Delivery') {
                    return order?.status?.Assembled?.state && !order?.status?.Dispatched?.state
                } else {
                    return order?.status?.Assembled?.state && !order?.status?.Pickup?.state
                }
            })
        },
        updateCancelledOrders: (state, list) => {
            const updated_orders = (list.filter(order => order.status?.Cancelled?.state))
            state.cancelledOrders = updated_orders
        },
        updateUnconfirmedOrders: (state, list) => {
            const updated_orders = list.filter(order => {
                if (order?.isSchedule) return (order.status?.AwaitingPayment?.state) && !order.status?.Cancelled?.state
                return (order.status?.AwaitingPayment?.state || !order.status?.Confirmed?.state) && !order.status?.Cancelled?.state
            })?.filter(order => !order?.awaitingPaystaclogoukPayment)
            state.unconfirmedOrders = updated_orders
        },
        updateScheduledOrders: (state, list) => {
            const updated_orders = list.filter(order => !order?.awaitingPaystackPayment && !order.status?.AwaitingPayment?.state && !order.status?.Cancelled?.state)
            state.scheduledOrders = updated_orders
        },

        update_orders_with_meal_reviews: (state, list) => {
            state.orders_with_meal_reviews = list

        },
        undefinedOrders: (state, list) => {
            state.undefined_orders = list
        },
        updateOrderItems: (state, { items, orderId, itemIndex }) => {
            state.orders[orderId].items[itemIndex] = items
        },
        updateRiders: (state, list) => {
            state.riders = list
        },
        stopcCancelledLoading: (state) => {
            state.cancelledLoading = null
        }
    },
    actions: {

        async updateRiders({ commit }) {
            try {
                console.log('updating riders')
                const res = await get(dbRef(getDatabase(), "courier"));
                let riders = res.val()
                commit('updateRiders', Object.values(riders || {}))
            } catch (error) {
                console.log(error)
            }
        },

        prepareItem: async ({ state, dispatch, commit }, payload) => {

            const { prepare, id, itemIndex, order, selectedOrder } = payload
            const item = "orders/" + id + "/items/" + itemIndex;
            const orderRef = dbRef(getDatabase(), item);
            // console.log(prepare, order.prepare)
            set(orderRef, {
                ...order,
                prepared: prepare,
                assembled: false,
            });
            const confirmedTime = dayjs(selectedOrder?.status?.Confirmed?.time?.replaceAll('"', ''))
            const item_preparations_time = ` (${dayjs().diff(confirmedTime, 'minutes')}mins) `
            // console.log(selectedOrder?.status?.Confirmed?.time)
            const isPrepared = order.prepared < prepare;
            await createTimeLine({
                orderId: id,
                user_type: 'admin',
                uid: state.user.uid,
                name: state.user.first_name + " " + state.user.last_name,
                message: `${order.prepared < prepare ? 'prepared' : 'unprepared'}  ${order.name} ${isPrepared ? item_preparations_time : ''} `,
            });
            dispatch('setPreparedStatus', { orderId: id })

        },
        completeMealPreparation: async ({ state, dispatch }, payload) => {
            const { orderId } = payload
            const data = await get(dbRef(getDatabase(), 'orders/' + orderId))
            const { items } = data.val()
            items.forEach((element, i) => {
                const item = "orders/" + orderId + "/items/" + i;
                const orderRef = dbRef(getDatabase(), item);
                set(orderRef, {
                    ...items[i],
                    prepared: items[i].quantity - (items[i].cancelled || items[i]?.canceled || 0),
                    assembled: false
                });
                dispatch('setPreparedStatus', { orderId })
            });
            await createTimeLine({
                orderId,
                user_type: 'admin',
                uid: state.user.uid,
                name: state.user.first_name + " " + state.user.last_name,
                message: `completed all meal preparations`,
            });
        },
        cancelItemAndExtra: async ({ state, dispatch }, payload) => {
            const { changes, orderId, count, order, index } = payload
            // await saveInitialOrderTotal(order)
            if (order?.items_cancelling) {
                toast.info('Please rectify previous action!')
                dispatch('setPreparedStatus', { orderId })
                return;
            }
            const item = "orders/" + orderId + "/items/";
            const orderRef = dbRef(getDatabase(), item);
            const itemPrev = (await get(orderRef)).val()
            const oldCancel = itemPrev[index]?.cancelled || itemPrev?.canceled || 0
            const newCancel = count
            // console.log({ oldCancel, newCancel })
            let isCredit = oldCancel < newCancel
            let isDebit = oldCancel > newCancel
            let message = ''
            let user = {};
            const extras = getExtras(null, null, itemPrev[index])
            let num = Math.abs(count - (itemPrev[index]?.cancelled || 0))
            const amount = (int(itemPrev[index]?.amount) + (sum(extras.map((extra) => int(extra?.amount) * (extra?.qty || 1))) || 0)) * num
            // console.log(count, itemPrev[index]?.cancelled)
            console.log({ amount, isCredit, isDebit, itemPrev, count })

            const mealPoint = (itemPrev[index]?.point * num)
            user = (
                await get(
                    dbRef(getDatabase(), `users/${order?.customer?.id}`)
                )
            ).val();

            const wallet = new WalletTransaction(user);
            // wallet.getWalletDetails

            const fn = async () => {
                await set(orderRef, changes);
                await remove(dbRef(getDatabase(), `orders/${orderId}/items_cancelling`))
                let new_total = 0
                if (isDebit) new_total = order?.total_price + amount;
                if (isCredit) new_total = order?.total_price - amount;
                await set(
                    dbRef(getDatabase(), `orders/${orderId}/total_price`),
                    new_total
                );
                createCancelledSummary({
                    order,
                    message,
                    amount,
                });
                // console.log()

                dispatch('setPreparedStatus', { orderId })
                state.cancelledLoading = null
                await createTimeLine({
                    orderId,
                    user_type: 'admin',
                    uid: state.user.uid,
                    name: state.user.first_name + " " + state.user.last_name,
                    message: `${message}, order total ${writeOrderTotalInTimeline(new_total)}`,
                });
            }

            if (isCredit) {
                message = `cancelled ${num} ${itemPrev[index]?.name} and it's extras ${formatPrice(amount)}`
                const initiateItemsCancelling = () => set(dbRef(getDatabase(), `orders/${orderId}/items_cancelling`), { amount, message, transactionType: "refund", transactionRectification: "charge" })
                await wallet.depositToWallet(amount, `refund for ${itemPrev[index]?.name} in ${getOrderID(order)}`, () => fn(), initiateItemsCancelling);
                if (mealPoint) await mealpointTransaction(order, mealPoint, null, 'add')
            }
            if (isDebit) {
                message = `restored ${num} ${itemPrev[index]?.name} and it's extras ${formatPrice(amount)}`
                const initiateItemsCancelling = () => set(dbRef(getDatabase(), `orders/${orderId}/items_cancelling`), { amount, message, transactionType: "charge", transactionRectification: "refund" })
                await wallet.withdrawFromWallet(amount, `charged for restore of ${itemPrev[index]?.name} in ${getOrderID(order)}`, () => fn(), null, initiateItemsCancelling);
                if (mealPoint) await mealpointTransaction(order, mealPoint, null, 'subtract')
            }

        },
        setPreparedStatus: async (context, payload) => {
            const { orderId } = payload
            const data = await get(dbRef(getDatabase(), 'orders/' + orderId))
            const { items, status } = data.val()
            const bool = items.filter((x) => x.prepared === (x.quantity - (x.cancelled || x?.canceled || 0))).length === items.length;
            const val = {
                ...status, Prepared: {
                    state: bool,
                    time: bool ? dayjs(Date.now()).format("MMM D YYYY, hh:mm A") : "",
                },
                Assembled: { state: false, time: "" },
            };
            const orderRef = dbRef(getDatabase(), "orders/" + orderId + "/status/")
            set(orderRef, val);
        },
        setAssembledStatus: async ({ state }, payload) => {
            const { orderId, reverse } = payload
            const data = await get(dbRef(getDatabase(), 'orders/' + orderId + ''))
            const { items, status, customer, locationType } = data.val()
            const bool = items.filter((x) => x.prepared === x.quantity - (x.cancelled || x?.canceled || 0)).length === items.length;
            const assembleBool = (items.filter(x => x.assembled).length === items.length) && bool;
            const val = {
                ...status,
                Assembled: { state: assembleBool, time: assembleBool ? dayjs(Date.now()).format("MMM D YYYY, hh:mm A") : "" },
            };
            const orderRef = dbRef(getDatabase(), "orders/" + orderId + "/status/")
            await set(orderRef, val);
            clearTimeout(asseble_recheck_timer)
            if (assembleBool && !reverse) {
                const user = {
                    email: state.user.email,
                    first_name: state.user.first_name,
                    last_name: state.user.last_name,
                    uid: state.user.uid,
                }
                const { data: result } = await orderUpdateMealInventory({ order: data?.val(), user, reason: `${orderId} assembled` })
                console.log(result, "---------")
                let updates = []
                if (result.success) {
                    const finished_items = (Object.values(result?.data || {})).filter(item => item?.stock_left <= 0)
                    finished_items.forEach(({ item }) => {
                        const occurances = getAllOccurancesOfItem(meals.state.value, item)
                        // console.log(occurances, item.name)
                        occurances?.occured_in.forEach(occurance => {
                            updates.push({ path: occurance.stoplist_path, value: false })
                        });
                    })
                }
                if (updates.length) await multipleUpdates({ updates })


                //send pickup notification only when all meals are assembled
                const snap = await get(dbRef(getDatabase(), `users/${customer?.id}/pushToken`))
                const token = (snap.val())
                if (locationType == 'Pickup') {
                    if (!token) {
                        toast.info('This customer will not receive a notication')
                    } else {
                        await sendPushNotification({
                            token,
                            title: ``,
                            body: "Your order is ready for pick up",
                            orderId
                        });
                    }
                }
            }

        },
        async assembleMeal({ state, dispatch }, payload) {
            const { assembled, itemIndex, orderId, order, selectedOrder } = payload
            // const isPrepared = (await get(dbRef(getDatabase(), 'orders/' + orderId + '/status/Prepared/state'))).val()
            const data = await get(dbRef(getDatabase(), 'orders/' + orderId + '/items'))
            const items = data.val()
            if (
                sum(items.map((x) => x.prepared)) ===
                sum(items.map((x) => x.quantity)) -
                sum(items.map((x) => (x.cancelled || x?.canceled || 0)))
            ) {
                // if (items[itemIndex].prepared > 0) {
                const item = "orders/" + orderId + "/items/" + itemIndex;
                const orderRef = dbRef(getDatabase(), item);
                await set(orderRef, {
                    ...items[itemIndex],
                    assembled
                });

                dispatch('setAssembledStatus', { orderId })
                // should be here so timeline functions doesn't interupt it

                // asseble_recheck_timer = setTimeout(() => {
                //     dispatch('setAssembledStatus', { orderId })
                // }, 5000)

                await createTimeLine({
                    orderId,
                    user_type: 'admin',
                    uid: state.user.uid,
                    name: state.user.first_name + " " + state.user.last_name,
                    message: `${assembled ? 'assembled' : 'unassembled'} ${order.name}`,
                });

                // } else {
                //     alert('All of this item was not prepared')
                // }
            } else {
                alert("This item is not prepared");
            }
        },
        async assembleAllMeals({ state, dispatch }, payload) {
            const { orderId, reverse = false } = payload
            const data = await get(dbRef(getDatabase(), 'orders/' + orderId))
            const order = data?.val()
            const items = data.val().items
            if (order.status.Prepared?.state) {
                // console.log(order.status.Prepared?.state)
                items.forEach((element, i) => {
                    const item = "orders/" + orderId + "/items/" + i;
                    const orderRef = dbRef(getDatabase(), item);
                    set(orderRef, {
                        ...items[i],
                        assembled: reverse ? false : true,
                    });

                });
                const user = {
                    email: state.user.email,
                    first_name: state.user.first_name,
                    last_name: state.user.last_name,
                    uid: state.user.uid,
                }
                console.log(reverse)
                if (reverse) {
                    const {data : result} = await orderUpdateMealInventory({ order, user, reason: `${orderId} reversed assembly`, type: 'add' })
                    console.log(result)
                    let updates = []
                    if (result.success) {
                        const finished_items = (Object.values(result?.data || {})).filter(item => item?.stock_left > 0)
                        console.log(result?.data, finished_items)
                        finished_items.forEach(({ item }) => {
                            const occurances = getAllOccurancesOfItem(meals.state.value, item)
                            occurances?.occured_in.forEach(occurance => {
                                updates.push({ path: occurance.stoplist_path, value: true })
                            });
                        })
                    }
                    console.log(updates)
                    if (updates.length) await multipleUpdates({ updates })
                }
                dispatch('setAssembledStatus', { orderId, reverse })
                await createTimeLine({
                    orderId,
                    user_type: 'admin',
                    uid: state.user.uid,
                    name: state.user.first_name + " " + state.user.last_name,
                    message: `${reverse ? 'reversed' : 'completed'} all meals assembly`,
                });

            } else {
                toast.info("Some items are still betokening prepared");
            }
        },

        setPickUp: async ({ state, dispatch }, payload) => {
            const { orderId, order, onComplete } = payload
            const data = await get(dbRef(getDatabase(), 'orders/' + orderId))
            const status = data.val().status

            await set(dbRef(getDatabase(), 'orders/' + orderId + '/status'), {
                ...status,
                Pickup: {
                    time: dayjs(Date.now()).format("MMM D YYYY, hh:mm A"),
                    state: true
                }
            })
            await remove(
                dbRef(getDatabase(), `orders/${orderId}/isProcessing`)
            );
            if (order?.referralUsed) await (rewardReferrer({ userId: order?.customer?.id }))
            if (getMealPointPrice(order)) {
                const random = new Date().getTime().toString();
                const mealPointID = random.substring(
                    random.length - 6,
                    random.length - 1
                );
                const mealPointID_path = `orders/${order?.id}/mealPointSaved/${mealPointID}`;
                await saveMealPoint({
                    orderId: order?.id,
                    total_price: getMealPointPrice(order),
                    userId: order.customer.id,
                    pointType: 'add',
                    point: "",
                    deliveryType: 'pickup',
                }).then(() => {
                    console.log('saved meal point')
                    set(dbRef(getDatabase(), mealPointID_path), true);
                }).catch(() => {
                    set(dbRef(getDatabase(), mealPointID_path), false)
                    console.log('!saved meal point')
                });
            }
            await createTimeLine({
                orderId,
                user_type: 'admin',
                uid: state.user.uid,
                name: state.user.first_name + " " + state.user.last_name,
                message: `confirmed order was picked up`,
            });
            await saveReviewId({
                userId: order.customer.id,
                orderId
            });
            // await orderUpdateMealInventory({ order })
            onComplete()
            store.commit("closeModal");
        },

        setOrderPrint: async ({ state, dispatch }, payload) => {
            const { order } = payload;
            // console.log('orders/' + order.id + '/print',order)
            await set(dbRef(getDatabase(), 'orders/' + order.id + '/printed'), true)
        }

    },
    plugins: [createPersistedState()]
})